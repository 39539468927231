/* eslint-disable comma-dangle */
import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-type": "application/json",
  },
});

export default {
  getSalesReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-report-sales", { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getProvisionEstimationReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-report-provision-estimation", { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getInstReminderReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-reminder-report", { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getInkasoReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-inkaso-report", { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
  getRodoReport(params) {
    const result = new Promise((resolve, reject) => {
      apiClient
        .get("/api/get-consents-report", { params })
        .then((response) =>
          response.data.status === "success"
            ? resolve(response)
            : reject(response)
        )
        .catch((error) => reject(error));
    });
    return result;
  },
};
